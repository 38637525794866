import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardService } from './service/dashboard.service';
import {HttpClientModule} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardNewComponent } from './pages/dashboard-new/dashboard-new.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardNewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [DashboardService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
