import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardNewComponent } from './pages/dashboard-new/dashboard-new.component';

const routes: Routes = [{
  path: '',

  children: [
    {
      path: '',
      component: DashboardNewComponent
    },
    {
      path: 'dashboard',
      component: DashboardComponent
    },
  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
