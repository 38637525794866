<video loop muted autoplay #video id="myVideo" (canplay)="video.play()" (loadedmetadata)="video.muted = true"  *ngIf="hideImage">
    <source [src]="videoSource" type="video/mp4">
</video>

<audio #firework controls style="visibility: hidden; position: absolute;">
    <source src="assets/audio/Firework.mp3" type="audio/mp3">
</audio>

<audio #coin controls style="visibility: hidden; position: absolute;">
    <source src="assets/audio/GameCoinsCollected.mp3" type="audio/mp3">
</audio>

<audio #happy controls style="visibility: hidden; position: absolute;" loop>
    <source src="assets/audio/TheHappy.mp3" type="audio/mp3">
</audio>


<main class="background_image" style="font-family: 'Myriad Pro'">
    <div class="row m_0">
        <div class="col-12 col-lg-9">
            <div class="row">
                <div class="col-1 col-lg-1"></div>
                <div class="col-11 col-lg-3 top-mattey-div">
                    <img src="assets/img2/L_Matty/L_Matty_Confetti.gif" alt="" class="m-auto img_width" style="   width: 100%; top: -10px;
                        position: relative;" *ngIf="image2">
                    <img src="assets/img2/L_Matty/L_Matty_Dance.gif" alt="" class="m-auto img_width" style="   width: 100%; top: -10px;
                         position: relative;" *ngIf="image4">
                    <img src="assets/img2/L_Matty/L_Matty_Jump.gif" alt="" class="m-auto img_width" style="   width: 100%; top: -10px;
                          position: relative;" *ngIf="image6">
                    <img src="assets/img2/L_Matty/L_Matty_Point.gif" alt="" class="m-auto img_width" style="   width: 100%; top: -10px;
                           position: relative;" *ngIf="image8">
                </div>
                <div class="col-12 col-lg-8 top-header-txt">
                    <span class="header_txt_1 font_size_1">MATTHEWS REWARDS </span>
                    <span class="header_txt_2 font_size_1">LIVE!</span><br>
                    <span class="header_txt_3 font_size_2">SAVE </span>
                    <span class="header_txt_4 font_size_2">in Service.</span>
                    <span class="header_txt_5 font_size_2"> EARN </span>
                    <span class="header_txt_6 font_size_2"> Towards Your Next Purchase, & </span>
                    <span class="header_txt_7 font_size_2"> WIN </span>
                    <span class="header_txt_8 font_size_2">Prizes!</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-7">
                    <div class="loading3" *ngIf="loading3"><img src="../../../assets/images/loading_mobile.gif"></div>
                    <div class="row">
                        <div class="col-12 col-lg-1" *ngIf="hideImage">
                            <p class="date">{{date}}</p>
                        </div>
                        <div class="col-6 col-lg-5" style="text-align: right;background-color: #0000001f;">
                            <p class="save_txt">SAVE</p>
                        </div>
                        <div class="col-6 col-lg-5" style=" background: #00000069;">
                            <p class="rewards_savings">${{dashboardData?.rewards_savings}}</p>
                            <p class="rewards_saving_txt">Rewards SAVINGS</p>
                        </div>
                        <div class="col-lg-1">
                        </div>

                    </div>
                    <div class="col-12 col-lg-12 table_div">
                        <table class="table align-middle text-white mb-0 data-table">
                            <tr style="font-weight: 600;
                                font-size: 16px;">
                                <td class="td1" style="padding-top: 2%;font-style: italic;">TIME</td>
                                <td class="td2" style="padding-top: 2%;font-style: italic;">MEMBER</td>
                                <td class="td3" style="padding-top: 2%;font-style: italic;">REWARD</td>
                                <td class="td4" style="padding-top: 2%;font-style: italic;">LOCATION</td>
                            </tr>
                            <tr *ngFor="let item of tableData; let i = index" [@rowAnimation]="i">
                                <td class="td1">{{item?.added_time}}</td>
                                <td class="td2">{{item?.user_full_name}}</td>
                                <td class="td3">{{item?.rewards_offer_title}}</td>
                                <td class="td4">{{item?.business_name}}</td>
                            </tr>

                        </table>
                    </div>
                </div>
                <div class="col-12 col-lg-1" *ngIf="hideImage">
                    <img src="assets/img2/Arrow_Save.gif" alt="" class="save-arrow">
                </div>
                <div class="col-12 col-lg-4" style="padding-right: 0px;" *ngIf="hideImage">
                    <div class="row live_look_win">
                        <div class="col-lg-4" style="    padding-left: 0px;">

                        </div>
                        <div class="col-lg-8 win-col">
                            <div class="row">
                                <div class="col-lg-12" style="padding-right: 0px;" *ngIf="hideImage">
                                    <img src="assets/img2/Arrow_Win.gif" alt="" class="m-auto winArrow">
                                </div>
                                <div class="col-lg-12 win-look-txt">
                                    <p class="win_text">WIN</p>
                                    <p class="live_look_text">LIVE LOOK IN</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2" style="
                             padding-right: 0px;">
                            <div style=" width: 5px;
                            height: 100%;
                            margin-left: 50%;
                            background: #edc01f;"></div>
                        </div>
                        <div class="col-lg-6" style="background-color: #000000ad;">
                            <p class="play-now">PLAY NOW!</p>
                        </div>
                        <div class="col-lg-4 bar-code-div">
                            <img [src]="dashboardData?.web_qr_code_image" alt="" class="m-auto bar_code">
                        </div>

                    </div>
                    <div class="row user_name_row">
                        <div class="col-lg-2">

                        </div>
                        <div class="col-lg-10 user_name_col">
                            <p class="user_name_txt"> {{spinGaneRewardsList?.user_full_name}} </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-1">
                </div>
                <div class="col-12 col-lg-11 mrt-2">
                    <div class="row">
                        <div class="col-lg-2"  *ngIf="hideImage">
                            <img src="assets/img2/Arrow_Earn.gif" alt="" class="earnArrow">

                        </div>
                        <div class="col-6 col-lg-3" style="border-right: 5px solid #edc01f;
                            background: #00000069;    text-align: right;
    padding-right: 20px;">
                            <p class="future_saving_txt">${{futureSavingEarn?.total_future_saving_earn}}</p>
                            <p class="matthews_case_txt">In Matthews Cash EARNED</p>
                        </div>
                        <div class="col-6 col-lg-4">
                            <p class="earn_txt" (click)="nextSlideDummy()">EARN</p>
                        </div>
                        <div class="col-lg-3"  *ngIf="hideImage">

                        </div>

                    </div>
                    <div class="container1" style="margin-top: 3%;   ">

                        <p class="date2" *ngIf="hideImage">{{date2}}</p>
                        <div class="column1" style="background: #ffffffb3;     border-right: 2px solid;"
                            *ngFor="let item of futureSavingEarn?.rewards_data; let i = index" [@slideAnimation]="i">
                            <div class="future-div">
                                <p class="future-div-name">{{item?.added_time}}</p>
                                <p class="future-div-name">{{item?.user_full_name}} <span
                                        style="font-weight: 900;">EARNED </span>
                                    <span style="font-weight: 900;">${{item?.future_vehicle_savings}}</span> at
                                </p>

                                <p class="future-div-business">{{item?.business_name}}</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4" style="padding-right: 0px;" *ngIf="!hideImage">
                <div class="row live_look_win">
                    <div class="col-lg-4" style="    padding-left: 0px;">

                    </div>
                    <div class="col-lg-8 win-col">
                        <div class="row">
                            <div class="col-lg-12" style="padding-right: 0px;" *ngIf="hideImage">
                                <img src="assets/img2/Arrow_Win.gif" alt="" class="m-auto winArrow">
                            </div>
                            <div class="col-lg-12 win-look-txt">
                                <p class="win_text">WIN</p>
                                <p class="live_look_text">LIVE LOOK IN</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" style="
                         padding-right: 0px;">
                        <div style=" width: 5px;
                        height: 100%;
                        margin-left: 50%;
                        background: #edc01f;"></div>
                    </div>
                    <div class="col-lg-6" style="background-color: #000000ad;">
                        <p class="play-now">PLAY NOW!</p>
                    </div>
                    <div class="col-lg-4 bar-code-div">
                        <img [src]="dashboardData?.web_qr_code_image" alt="" class="m-auto bar_code">
                    </div>

                </div>
                <div class="row user_name_row">
                    <div class="col-lg-2">

                    </div>
                    <div class="col-lg-10 user_name_col">
                        <p class="user_name_txt"> {{spinGaneRewardsList?.user_full_name}} </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3">

            <div class="row">
                <div class="col-12">
                    <div class="MobileScreenNew mobileScreeMargin">
                        <div class="loading2" *ngIf="loading2"><img src="../../../assets/images/loading_mobile.gif"></div>
                        <img src="../assets/images/spin_screen_bg@3x.png" alt="" class="inner_bulb_img w-100">

                        <div class="swiper swiper-new">
                            <div class="swiper-wrapper">
                                <div class="TopBorder">
                                    <img src="../assets/images/top_Layer@2x.png" alt="">
                                </div>
                                <div class="swiper-slide" *ngFor="let item of spinGaneRewardsList?.spin_rewards_list">
                                    <div class="InnerContanetNew">
                                        <h5 class="wrap_text swiper_spinner_heading">{{item?.business_name}}</h5>
                                        <h5 class="wrap_text">{{item?.rewards_title}}</h5>
                                        <h6 class="wrap_text">{{item?.rewards_offer_title}}</h6>
                                    </div>
                                </div>
                                <div class="BottomBorder">
                                    <img src="../assets/images/bottom_layer@2x.png" alt="">
                                </div>

                            </div>
                        </div>
                        <div class="swiper-button-next swiper-button-position">
                            <img src="../assets/images/spin_button@2x.png" alt="" class="spin-next-button-img">
                        </div>

                        <div class="LogoTop">
                            <img [src]="spinGaneRewardsList?.spin_the_wheel_header" alt="">
                        </div>
                        <div class="PointBlance pointBalanceNew_div">
                            <p class="m-0">Point Balance <span
                                    class="pointBalanceNew_txt">{{spinGaneRewardsList?.point_balance}}</span></p>
                        </div>
                        <div class="PointBlanceNew2">
                            <img src="../assets/images/outer_inner_blue2x.png" alt="" class="outer_img">
                        </div>
                        <div class="PointBlance3">
                            <img src="../assets/img2/PhoneHand.png" alt="" class="phoneHand">
                        </div>
                        <div class="BottomBar">
                            <div class="BottomIcons">
                                <img src="../assets/images/message@2x.png" alt="">
                            </div>
                            <div class="CenterIcon">
                                <img src="../assets/images/tab_logo@2x.png" alt="">
                            </div>
                            <div class="BottomIcons">
                                <img src="../assets/images/menu@2x.png" alt="">
                            </div>
                        </div>
                        <div class="Popup01" [ngClass]="[ nagativePopup ? 'pupup1' : 'pupup1None']">
                            <div class="bussinessinfo">
                                <div class="InnerInfo">
                                    <div class="TopItitle">
                                        <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                                    </div>
                                    <div class="RewD">
                                        <h3>You Lose</h3>
                                    </div>
                                    <div class="Claim">
                                        <p>-{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points, Wll ok then
                                        </p>
                                        <div class="GroupBtn">
                                            <button>Keep spinning</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="Popup02" [ngClass]="[ positivePopUp ? 'pupup1' : 'pupup1None']">
                            <div class="bussinessinfo">
                                <div class="InnerInfo">
                                    <div class="TopItitle">
                                        <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                                    </div>
                                    <div class="RewD">
                                        <h3>+{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points! Nice!</h3>
                                    </div>
                                    <div class="Claim">
                                        <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                                        <div class="GroupBtn">
                                            <button>Let it Ride</button>&nbsp;&nbsp;
                                            <button class="mrg-top">Claim</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="Popup02" [ngClass]="[ everyDayReward ? 'pupup1' : 'pupup1None']">
                            <div class="bussinessinfo">
                                <div class="InnerInfo">
                                    <div class="TopItitle">
                                        <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                                    </div>
                                    <div class="RewD">
                                        <h3>+{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points! NIce!</h3>
                                    </div>
                                    <div class="Claim">
                                        <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                                        <div class="GroupBtn">
                                            <button>Claim</button>&nbsp;&nbsp;
                                            <button>Decline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="Popup02" [ngClass]="[ raffleReward ? 'pupup1' : 'pupup1None']">
                            <div class="bussinessinfo">
                                <div class="InnerInfo">
                                    <div class="TopItitle">
                                        <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                                        <p>{{rewardsTransactionPopupDetails?.rewards_offer_title}}</p>
                                    </div>
                                    <div class="RewD">
                                        <img [src]="rewardsPopupDetails?.data?.rewards_image_url" alt=""
                                            class="m-auto img_width_30">
                                    </div>
                                    <div class="Claim">
                                        <h3>{{rewardsTransactionPopupDetails?.reward_description}}</h3>
                                        <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                                        <div class="GroupBtn">
                                            <button>Claim</button>&nbsp;&nbsp;
                                            <button>Decline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12" style="text-align: center;min-height: 151px; ">
                    <img src="assets/img2/R_Matty/R_Matty_Confetti.gif" alt="" class="bottom_matty_img" *ngIf="image1">
                    <img src="assets/img2/R_Matty/R_Matty_Dance.gif" alt="" class="bottom_matty_img" *ngIf="image3">
                    <img src="assets/img2/R_Matty/R_Matty_Jump.gif" alt="" class="bottom_matty_img" *ngIf="image5">
                    <img src="assets/img2/R_Matty/R_Matty_Point.gif" alt="" class="bottom_matty_img" *ngIf="image7">
                </div>
            </div>
        </div>
    </div>
</main>


<div id="winner-overlay">
    <div class="pyro">
        <div class="before"></div>
        <div class="after"></div>
    </div>
</div>


<div class="modal fade" id="audioModel" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static"
    aria-labelledby="audioModel">
    <div class="modal-dialog" role="document" style="    width: 402px;">
        <div class="modal-content music-popup">
            <div class="modal-header" style="border-bottom: none;padding: 15px 5px 5px 20px;">
                <h5 class="modal-title">Enable AutoPlay Audio</h5>

            </div>
            <div class="modal-body" style="    padding: 5px 5px 5px 20px;">
                <p style="margin-bottom: 0px;"><img src="assets/img2/music.png" alt="" style="width: 7%;"
                        class="m-auto"> The 'Rewards Live' website is seeking to enhance user experience by
                    incorporating sound elements.</p>
            </div>
            <div class="modal-footer" style="padding: 0px; border-top: none;">
                <button type="button" class="btn btn-primary allow-deny-btn" data-dismiss="modal"
                    (click)="close()">Deny</button>
                <button type="button" class="btn btn-secondary allow-deny-btn" (click)="enableAudio()">Allow</button>
            </div>
        </div>
    </div>
</div>