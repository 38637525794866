import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { error } from 'console';
import { DashboardService } from 'src/app/service/dashboard.service';
import Swiper from 'swiper';
declare var $: any;
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss'],
  animations: [
    trigger('rowAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('3200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('700ms', style({ opacity: 0 })),
      ]),
    ]),
    // trigger('slideAnimation', [
    //   transition(':increment', [
    //     style({ transform: 'translateX(-100%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(0)' })),
    //   ]),
    //   transition(':decrement', [
    //     style({ transform: 'translateX(100%)' }),
    //     animate('0.5s ease-in-out', style({ transform: 'translateX(0)' })),
    //   ]),
    // ]),
    trigger('slideAnimation', [
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('0.5s ease-out', style({ opacity: 0, transform: 'translateX(100%)' })),
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),

  ]
})
export class DashboardNewComponent implements OnInit, AfterViewInit, OnDestroy {

  mySwiper: any;
  dashboardData: any;
  futureSavingEarn: any;
  tableData: any;
  reverdTransactionList: any;
  spinGaneRewardsList: any;
  rewardsTransactionPopupDetails: any;
  rewardsPopupDetails: any;

  dashboarApiResponse = false;
  futureApiResponse = false;
  tableDataApiResponse = false;

  nagativePopup = false;
  positivePopUp = false;
  everyDayReward = false;
  raffleReward = false;

  winnerAvailable = false;
  coinCollect = false;

  pageSize = 0;
  popUpPage = 0;

  loading = false;
  loading2 = false;
  loading3 = false;

  i = 1;

  myInterval: any;
  tableDataInterval: any;
  dashboardInterval: any;
  futureInterval: any;
  tableRowUpdateInterval: any;
  futureEarnInterval: any;
  imageInterwal: any;

  image1 = true;
  image2 = false;
  image3 = false;
  image4 = false;
  image5 = false;
  image6 = false;
  image7 = false;
  image8 = false;

  // isLocal = 'false';

  @ViewChild('firework')
  firework: any;
  @ViewChild('coin')
  coin: any;

  @ViewChild('happy')
  happyAudio: any;

  @ViewChild('yourDiv')
  yourDiv!: ElementRef;

  items = new Array();

  date: any;
  date2: any

  tablePageSize = 0;
  // futureEarnSize = 0;
  // Function to add a new item to the top of the list
  addItem(newItem: any) {
    // this.tableData.unshift(newItem);
    this.tableData.push(newItem);
  }

  // Function to remove the last item from the list
  removeLastItem() {
    if (this.tableData && this.tableData.length >= 7) {
      // this.tableData.pop();
      this.tableData.shift()
    }
  }



  // simulateData() {
  //   let data = {
  //     "rewards_transactions_id": "1210237",
  //     "business_name": "Matthews Auto",
  //     "user_id": "5",
  //     "user_full_name": "Harsh K.",
  //     "rewards_id": "165",
  //     "rewards_title": "$20 OFF $100",
  //     "rewards_offer_title": "$20 OFF $100 Parts Bill!",
  //     "rewards_status": "Used",
  //     "added_date": "Jan 05,2024",
  //     "added_time": "15:05 PM"
  //   };
  //   this.simulateDataUpdate(data);
  // }
  // Example method to simulate adding a new item with animation
  simulateDataUpdate(newItem: any) {
    // Remove last item with animation
    this.removeLastItem();

    this.addItem(newItem);
  }


  constructor(private el: ElementRef, private readonly dashboardService: DashboardService, private datePipe: DatePipe) {
    // this.isLocal = JSON.parse(JSON.stringify(localStorage.getItem("isLocal")));
    // const formattedDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    // let date = localStorage.getItem("currentDate");
    // if (date == null) {
    //   localStorage.setItem("currentDate", formattedDate != null ? formattedDate : "");
    // } else if (date != formattedDate) {
    //   localStorage.setItem("currentDate", formattedDate != null ? formattedDate : "");
    // } else {
    //   this.updatePageValue();
    // }

  }
  ngOnDestroy(): void {
    clearInterval(this.myInterval);
    clearInterval(this.tableDataInterval);
    clearInterval(this.dashboardInterval);
    clearInterval(this.futureInterval);
    clearInterval(this.tableRowUpdateInterval);
    clearInterval(this.imageInterwal);
  }


  // @HostListener('window:resize', ['$event'])
  // getHeight() {

  //   const divHeight = this.yourDiv.nativeElement.offsetHeight;
  //   // Set the custom height to the div
  //   this.yourDiv.nativeElement.style.height = divHeight + 'px';
  // }

  imageChange() {
    this.imageInterwal = setInterval(() => {
      if (this.image1) {
        this.image1 = false
        this.image2 = true;
      } else if (this.image2) {
        this.image2 = false
        this.image3 = true;
      } else if (this.image3) {
        this.image3 = false
        this.image4 = true;
      } else if (this.image4) {
        this.image4 = false
        this.image5 = true;
      } else if (this.image5) {
        this.image5 = false
        this.image6 = true;
      } else if (this.image6) {
        this.image6 = false
        this.image7 = true;
      } else if (this.image7) {
        this.image7 = false
        this.image8 = true;
      } else if (this.image8) {
        this.image8 = false
        this.image1 = true;
      }

    }, 10000);
  }

  // updatePageValue() {
  //   // let pageSize = localStorage.getItem("pageSize");
  //   // if (pageSize != null && pageSize.length > 0 && Number(pageSize) > 0) {
  //   //   this.pageSize = Number(pageSize);
  //   // }
  //   // let popupSize = localStorage.getItem("popupPage");
  //   // if (popupSize != null && popupSize.length > 0 && Number(popupSize) > 0) {
  //   //   this.popUpPage = Number(popupSize);
  //   // }
  //   // let tablePage = JSON.parse(JSON.stringify(localStorage.getItem("tablePageSize")));
  //   // if (tablePage != null) {
  //   //   this.tablePageSize = tablePage;
  //   // }
  //   // let futurePage = JSON.parse(JSON.stringify(localStorage.getItem("futurePageSize")));
  //   // if (futurePage != null) {
  //   //   this.futureEarnSize = futurePage;
  //   // }
  // }

  // updatePopupPage(data: any) {
  //   localStorage.setItem("popupPage", data);
  // }

  updatePageSize(data: any) {
    localStorage.setItem("pageSize", data);
  }

  ngOnInit() {
    this.loading2 = true;
    this.getDashboardData(true);

    this.getFutureSavingEarn(true);

    this.getTableData(true);

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 500) {
      this.hideImage = false;
    } else {
      this.hideImage = true;
    }

    this.dashboardInterval = setInterval(() => {
      if (this.dashboarApiResponse) {
        this.dashboarApiResponse = false;
        this.getDashboardData(false);
      }
    }, 20000);
    this.futureInterval = setInterval(() => {
      if (this.futureApiResponse) {
        this.futureApiResponse = false;
        this.getFutureSavingEarn(false);
      }
    }, 25000);
    this.tableDataInterval = setInterval(() => {
      if (this.tableDataApiResponse) {
        this.tableDataApiResponse = false;
        this.getTableData(false);
      }
    }, 30000);

    this.getReverdTransactionList();

    this.imageChange();
  }
  innerWidth = 1000;
  hideImage = true;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 500) {
      this.hideImage = false;
    } else {
      this.hideImage = true;
    }
  }


  getDashboardData(firstTime: any) {
    this.loading = true;
    this.dashboardService.getDashboardData().subscribe({
      next: (data: any) => {
        this.loading = false;
        this.dashboarApiResponse = true;
        if (!this.audioDisable) {
          if (firstTime) {
            this.coin.nativeElement.play();
          }
          if (data.data && data.data.rewards_savings && this.dashboardData && this.dashboardData.rewards_savings) {
            if (data.data.rewards_savings !== this.dashboardData.rewards_savings) {
              this.coin.nativeElement.play();
            }
          }
        }
        this.dashboardData = data.data;
      },
      error: (error: any) => {
        this.dashboarApiResponse = true;
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.dashboarApiResponse = true;
        this.loading = false;
        // console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }




  getFutureSavingEarn(firstTime: any) {
    this.loading = true;
    // let obj = {
    //   page_no: this.futureEarnSize
    // }
    this.dashboardService.getFutureSavingEarn().subscribe({
      next: (data: any) => {
        this.loading = false;
        this.futureApiResponse = true;
        if (data.data.rewards_data && data.data.rewards_data.length > 0) {
          this.date2 = data.data.rewards_data[0].added_date;
        }



        if (data.data && data.data.rewards_data && this.futureSavingEarn && this.futureSavingEarn.rewards_data) {

          if (data.data && data.data.total_future_saving_earn) {
            this.futureSavingEarn.total_future_saving_earn = data.data.total_future_saving_earn;
            if (this.futureSavingEarn && this.futureSavingEarn.total_future_saving_earn != data.data.total_future_saving_earn) {
              if (!this.audioDisable) {
                this.coin.nativeElement.play();
              }
            }
          } else {
            this.futureSavingEarn.total_future_saving_earn = data.data.total_future_saving_earn;
          }

          const uniqueData = data.data.rewards_data.filter((obj2: any) => !this.futureSavingEarn.rewards_data.find((obj1: any) => obj1.rewards_transactions_id === obj2.rewards_transactions_id));

          if (uniqueData && uniqueData.length > 0) {
            let j = uniqueData.length;
            let i = 0;
            this.popup();
            setTimeout(() => {
              this.closeWinnerPopup();
            }, 5000);

            this.futureEarnInterval = setInterval(() => {
              this.nextSlide(uniqueData[i]);
              i++;
              if (i == j) {
                clearInterval(this.futureEarnInterval);
              }
            }, 1000);
            if (!this.audioDisable) {
              this.firework.nativeElement.play();
            }
          }
        } else {
          this.futureSavingEarn = data.data;
        }
      },
      error: (error: any) => {
        this.futureApiResponse = true;
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.futureApiResponse = true;
        this.loading = false;
        // console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }

  nextSlideDummy() {
    this.nextSlide({
      "business_name": "Matthews Rewards Plus",
      "user_full_name": "PATRICA K.",
      "future_vehicle_savings": "0.00",
      "added_date": "11|30|20",
      "added_time": "09:10 PM"
    });
    if (!this.audioDisable) {
      this.firework.nativeElement.play();
    }
  }

  nextSlide(data: any) {
    if (this.futureSavingEarn.rewards_data && this.futureSavingEarn.rewards_data.length >= 4) {
      this.futureSavingEarn.rewards_data.pop();
    }

    setTimeout(() => {
      this.futureSavingEarn.rewards_data.unshift(data);
      setTimeout(() => {
        if (this.futureSavingEarn.rewards_data && this.futureSavingEarn.rewards_data.length >= 5) {
          this.futureSavingEarn.rewards_data.pop();
        }
      }, 500);
    }, 500);

  }


  getTableData(firstTime: any) {
    // this.loading = true;
    let obj = {
      page_no: this.tablePageSize
    }
    if (firstTime) {
      this.loading3 = true;
    }
    this.dashboardService.getTableData2(obj).subscribe({
      next: (data: any) => {
        this.tableDataApiResponse = true;
        this.loading3 = false;
        if (data.data && !data.data.flag) {
          this.tablePageSize++;
          // localStorage.setItem("tablePageSize", this.tablePageSize + '');
        } else {
          this.tablePageSize = 0;
        }
        if (firstTime) {
          if (!this.audioDisable) {
            this.firework.nativeElement.play();
          }
        }
        if (data.data.rewards_data) {
          this.date = data.data.rewards_data[0].added_date;
        }
        if (this.tableData && this.tableData.length > 0) {
          const uniqueData = data.data.rewards_data.filter((obj2: any) => !this.tableData.find((obj1: any) => obj1 && obj2 && (obj1.rewards_transactions_id === obj2.rewards_transactions_id)));
          // if (this.tableData != null && this.tableData.length > 0) {
          // let transactionId = this.tableData[0].rewards_transactions_id;
          // let transactionId2 = localStorage.getItem("transactionId");
          // if (transactionId != transactionId2) {
          if (uniqueData && uniqueData.length > 0) {
            let j = uniqueData.length;
            let i = 0;

            this.tableRowUpdateInterval = setInterval(() => {
              if (uniqueData[i] && uniqueData[i].rewards_transactions_id) {
                this.simulateDataUpdate(uniqueData[i]);
              }
              i++;
              if (i == j) {
                clearInterval(this.tableRowUpdateInterval);
              }
            }, 2000);
            this.popup();
            // this.winnerAvailable = true;
            if (!this.audioDisable) {
              this.firework.nativeElement.play();
            }
            setTimeout(() => {
              // this.winnerAvailable = false;
              this.closeWinnerPopup();
            }, 8000);
          }
          // }
          // localStorage.setItem("transactionId", this.tableData[0].rewards_transactions_id);
          // }
        } else {
          this.tableData = data.data.rewards_data;
        }
        // this.tableData = [];

        // setTimeout(() => {
        //   this.getHeight();
        // }, 1000);

      },
      error: (error: any) => {
        this.tableDataApiResponse = true;
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.tableDataApiResponse = true;
        this.loading = false;
        // console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }

  getReverdTransactionList(data?: any) {
    this.loading = true;
    this.dashboardService.getReverdTransactionList().subscribe({
      next: (data: any) => {
        this.reverdTransactionList = data.data;
        const user_id = this.reverdTransactionList.rewards_data.map((item: any) => item.user_id);
        console.log(user_id);
        const rewards_id = this.reverdTransactionList.rewards_data.map((item: any) => item.rewards_id);
        console.log(rewards_id);
        if (this.reverdTransactionList != null && this.reverdTransactionList.rewards_data && this.reverdTransactionList.rewards_data.length > 0) {
          this.rewardsTransactionPopupDetails = this.reverdTransactionList.rewards_data[this.popUpPage];
          let obj2 = {
            user_id: this.rewardsTransactionPopupDetails.user_id
          }
          this.getSpinGaneRewardsList(obj2);
          let obj3 = {
            user_id: this.rewardsTransactionPopupDetails.user_id,
            reward_id: this.rewardsTransactionPopupDetails.rewards_id
          }
          this.getRewardsPopupDetails(obj3);
          this.loading = false;
        }
      },
      error: (error: any) => {
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        // Optional: Logic to handle completion
      }
    });
  }


  getSpinGaneRewardsList(dataObj: any) {
    this.loading = true;
    this.loading2 = true;

    this.dashboardService.getSpinGaneRewardsList(dataObj).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.loading2 = false;
        // this.spinGaneRewardsList = [];
        this.spinGaneRewardsList = data.data;
        this.popupindex = this.spinGaneRewardsList.spin_rewards_list.findIndex((obj: any) => obj.rewards_id == this.rewardsTransactionPopupDetails.rewards_id);
        if (this.popupindex == -1) {
          // let obj = {
          //   page_no: this.pageSize
          // }
          this.loading = true;
          this.getReverdTransactionList();
          return;
        }
       
        setTimeout(() => {
          this.initilizaSwiper();
        }, 50);

      },
      error: (error: any) => {
        this.loading = false;
        this.loading2 = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        this.loading2 = false;
        // Optional: Logic to handle completion
      }
    });
  }

  getRewardsPopupDetails(data: any) {
    this.dashboardService.getRewardsPopupDetails(data).subscribe({
      next: (data: any) => {
        this.rewardsPopupDetails = data;
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        // console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }

  audioDisable = true;


  ngAfterViewInit() {
    let enable = JSON.parse(JSON.stringify(localStorage.getItem("enalbeAudio")));
    setTimeout(() => {
      $('#audioModel').modal({
        backdrop: 'static',
        keyboard: false  // to prevent closing with Esc button (if you want this too)
      })
      $('#audioModel').modal('show');
    }, 500);
  }

  enableAudio() {
    $('#audioModel').modal('hide');
    localStorage.setItem("enalbeAudio", 'true');
    this.audioDisable = false;
    this.firework.nativeElement.play();
    this.coin.nativeElement.play();
    this.happyAudio.nativeElement.play();
  }

  close() {
    this.audioDisable = true;
    this.firework.nativeElement.pause();
    this.coin.nativeElement.pause();
    this.happyAudio.nativeElement.pause();
    $('#audioModel').modal('hide');
  }
  videoSource: string = 'assets/video/BG_30.mp4';

  initilizaSwiper() {
    this.mySwiper = new Swiper(this.el.nativeElement.querySelector('.swiper'), {
      slidesPerView: 3,
      direction: 'vertical',
      rewind: true,
      speed: 300,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    setTimeout(() => {
      this.change();
    }, 200);
  }
  spinnerSpeed = 150;
  popupindex = 0;
  spinnerIndex = 3;
  change() {
    this.spinnerSpeed = 150;
    this.i = 0;
    this.spinnerIndex = 3;
    this.startIntervel();
  }



  startIntervel() {
    this.myInterval = setInterval(() => {
      // let k = 0;
      // if (this.i == 1 || this.i == this.spinGaneRewardsList.spin_rewards_list.length - 1) {
      //   if (this.i == this.spinGaneRewardsList.spin_rewards_list.length - 1) {
      //     k = this.spinGaneRewardsList.spin_rewards_list.length - 1;
      //   }
      //   if (this.spinGaneRewardsList.spin_rewards_list[k].rewards_id == this.rewardsTransactionPopupDetails.rewards_id) {
      //     clearInterval(this.myInterval);
      //     setTimeout(() => {
      //       if (this.rewardsPopupDetails != null) {
      //         this.showPopup(this.rewardsPopupDetails.data, true);
      //       }
      //     }, 400);
      //     setTimeout(() => {
      //       clearInterval(this.myInterval);
      //       this.showPopup(this.rewardsPopupDetails.data, false);
      //       if (this.popUpPage == this.reverdTransactionList.rewards_data.length - 1) {
      //         this.popUpPage = 0;
      //         clearInterval(this.myInterval);
      //         this.getReverdTransactionList();
      //         return;
      //       }
      //       this.popUpPage++;
      //       if (this.reverdTransactionList.rewards_data.length > this.popUpPage) {
      //         this.rewardsTransactionPopupDetails = this.reverdTransactionList.rewards_data[this.popUpPage];
      //         let obj2 = {
      //           user_id: this.rewardsTransactionPopupDetails.user_id
      //         }
      //         clearInterval(this.myInterval);
      //         this.getSpinGaneRewardsList(obj2);
      //         let obj3 = {
      //           user_id: this.rewardsTransactionPopupDetails.user_id,
      //           reward_id: this.rewardsTransactionPopupDetails.rewards_id
      //         }
      //         this.getRewardsPopupDetails(obj3)
      //       }
      //     }, 1800);
      //     return;
      //   }
      // }

      // if(this.i==this.spinnerIndex){      
      this.mySwiper.slideTo(this.spinnerIndex);
      // }
      // console.log(this.i);

      if (this.spinGaneRewardsList.spin_rewards_list[this.i].rewards_id == this.rewardsTransactionPopupDetails.rewards_id) {
        clearInterval(this.myInterval);
        setTimeout(() => {
          if (this.rewardsPopupDetails != null) {
            this.showPopup(this.rewardsPopupDetails.data, true);
          }
        }, 400);
        setTimeout(() => {
          clearInterval(this.myInterval);
          //close popup
          this.showPopup(this.rewardsPopupDetails.data, false);
          if (this.popUpPage == this.reverdTransactionList.rewards_data.length - 1) {
            this.popUpPage = 0;
            clearInterval(this.myInterval);
            this.getReverdTransactionList();
            return;
          }
          this.popUpPage++;
          if (this.reverdTransactionList.rewards_data.length > this.popUpPage) {
            this.rewardsTransactionPopupDetails = this.reverdTransactionList.rewards_data[this.popUpPage];
            let obj2 = {
              user_id: this.rewardsTransactionPopupDetails.user_id
            }
            clearInterval(this.myInterval);
            this.getSpinGaneRewardsList(obj2);
            let obj3 = {
              user_id: this.rewardsTransactionPopupDetails.user_id,
              reward_id: this.rewardsTransactionPopupDetails.rewards_id
            }
            this.getRewardsPopupDetails(obj3)
          }
        }, 1800);
        return;
      }
      
      if (this.spinnerIndex >= this.spinGaneRewardsList.spin_rewards_list.length - 1) {
        this.spinnerIndex = 0;
      }else{
        this.spinnerIndex++;
      }
      if(this.i>this.spinGaneRewardsList.spin_rewards_list.length - 1){
        this.i=0;
      }else{
        this.i++;
      }
      const speedDeffrance = this.popupindex - this.i;
      if (speedDeffrance > 60) {
        if (this.spinnerSpeed != 100) {
          this.spinnerSpeed = 100;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 15) {
        if (this.spinnerSpeed != 150) {
          this.spinnerSpeed = 150;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 10) {
        if (this.spinnerSpeed != 200) {
          this.spinnerSpeed = 200;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 6) {
        if (this.spinnerSpeed != 250) {
          this.spinnerSpeed = 250;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 3) {
        if (this.spinnerSpeed != 300) {
          this.spinnerSpeed = 300;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      }


    }, this.spinnerSpeed);
  }
  // changeSwiper() {
  //   this.mySwiper.slideTo(this.i++);
  //   if (this.i == this.spinGaneRewardsList.spin_rewards_list.length) {
  //     this.i = 0;
  //   }
  // }

  showPopup(data: any, value: boolean) {
    switch (data.is_check_positive_negative_point) {
      case "0":
        switch (data.rewards_type_id) {
          case "1":
            this.raffleReward = value;
            break;
          case "2":
            this.positivePopUp = value;
            break;
          case "3":
            this.positivePopUp = value;
            break;
          case "4":
            this.positivePopUp = value;
            break;
          case "7":
            this.raffleReward = value;
            break;
          default:
            break;
        }
        break;
      case "1":
        this.positivePopUp = value;
        break;
      case "2":
        this.nagativePopup = value;
        break;
      default:
        break;
    }
  }

  playWinnerSound() {
    this.firework.nativeElement.play();

  }

  playCoinSound() {
    this.coin.nativeElement.play();
  }





  closeWinnerPopup() {
    $('#demoModal').modal('hide');
    const winnerElement: HTMLElement = document.getElementById("winner-overlay")!;
    if (winnerElement) {
      winnerElement.className = "";
    }
  }

  popup() {
    $('#demoModal').modal('show');
    const winnerElement: HTMLElement = document.getElementById("winner-overlay")!;
    if (winnerElement) {
      winnerElement.className += " display";
    }
  }

}