import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';
import Swiper from 'swiper';
declare var $: any;
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('rowAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('2000ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2000ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  mySwiper: any;
  dashboardData: any;
  tableData: any;
  reverdTransactionList: any;
  spinGaneRewardsList: any;
  rewardsTransactionPopupDetails: any;
  rewardsPopupDetails: any;

  nagativePopup = false;
  positivePopUp = false;
  everyDayReward = false;
  raffleReward = false;

  winnerAvailable = false;
  coinCollect = false;

  pageSize = 0;
  popUpPage = 0;

  loading = false;
  loading2 = false;

  i = 0;

  myInterval: any;
  tableDataInterval: any;
  dashboardInterval: any;
  tableRowUpdateInterval: any;

  image1 = true;
  image2 = false;
  image3 = false;
  image4 = false;
  image5 = false;

  @ViewChild('firework')
  firework: any;
  @ViewChild('coin')
  coin: any;

  @ViewChild('yourDiv')
  yourDiv!: ElementRef;


  // Function to add a new item to the top of the list
  addItem(newItem: any) {
    this.tableData.unshift(newItem);
  }

  // Function to remove the last item from the list
  removeLastItem() {
    this.tableData.pop();
  }

  simulateData(){
    let data = {
      "rewards_transactions_id": "1210237",
      "business_name": "Matthews Auto",
      "user_id": "5",
      "user_full_name": "Harsh K.",
      "rewards_id": "165",
      "rewards_title": "$20 OFF $100",
      "rewards_offer_title": "$20 OFF $100 Parts Bill!",
      "rewards_status": "Used",
      "added_date": "Jan 05,2024",
      "added_time": "15:05 PM"
  };
  this.simulateDataUpdate(data);
  }
  // Example method to simulate adding a new item with animation
  simulateDataUpdate(newItem:any) {
    // Remove last item with animation
    this.removeLastItem();

    this.addItem(newItem);
  }

  constructor(private el: ElementRef, private readonly dashboardService: DashboardService, private datePipe: DatePipe) {
    const formattedDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    let date = localStorage.getItem("currentDate");
    if (date == null) {
      localStorage.setItem("currentDate", formattedDate != null ? formattedDate : "");
    } else if (date != formattedDate) {
      localStorage.setItem("currentDate", formattedDate != null ? formattedDate : "");
    } else {
      this.updatePageValue();
    }

  }
  ngOnDestroy(): void {
    clearInterval(this.myInterval);
    clearInterval(this.tableDataInterval);
    clearInterval(this.dashboardInterval);
    clearInterval(this.tableRowUpdateInterval);
  }
 

  @HostListener('window:resize', ['$event'])
  getHeight() {

    const divHeight = this.yourDiv.nativeElement.offsetHeight;
    // Set the custom height to the div
    this.yourDiv.nativeElement.style.height = divHeight + 'px';
  }

  imageChange() {
    // setInterval(() => {
    //   if (this.image1) {
    //     this.image1 = false
    //     this.image2 = true;
    //   } else if (this.image2) {
    //     this.image2 = false
    //     this.image3 = true;
    //   } else if (this.image3) {
    //     this.image3 = false
    //     this.image4 = true;
    //   } else if (this.image4) {
    //     this.image4 = false
    //     this.image5 = true;
    //   } else if (this.image5) {
    //     this.image5 = false
    //     this.image1 = true;
    //   }

    // }, 5000);
  }

  updatePageValue() {
    let pageSize = localStorage.getItem("pageSize");
    if (pageSize != null && pageSize.length > 0 && Number(pageSize) > 0) {
      this.pageSize = Number(pageSize);
    }
    let popupSize = localStorage.getItem("popupPage");
    if (popupSize != null && popupSize.length > 0 && Number(popupSize) > 0) {
      this.popUpPage = Number(popupSize);
    }
  }

  updatePopupPage(data: any) {
    localStorage.setItem("popupPage", data);
  }

  updatePageSize(data: any) {
    localStorage.setItem("pageSize", data);
  }

  ngOnInit() {
    this.loading2 = true;
    this.getDashboardData(true);
    
    this.getTableData(true);
   
   
    this.dashboardInterval = setInterval(() => {
      this.getDashboardData(false);
    }, 25000);
    this.tableDataInterval = setInterval(() => {
      this.getTableData(false);
      //  this.winnerAvailable = true;
      //   setTimeout(() => {
      //         this.winnerAvailable = false;
      //       }, 8000);
    }, 30000);
    let obj = {
      page_no: this.pageSize
    }
    this.getReverdTransactionList(obj);

    this.imageChange();
  }


  getDashboardData(firstTime:any) {
    this.loading = true;
    this.dashboardService.getDashboardData().subscribe({
      next: (data: any) => {
        this.loading = false;
        if(firstTime){
          this.coin.nativeElement.play();
        }
        if (data.data && data.data.rewards_savings && data.data.rewards_savings.today && this.dashboardData && this.dashboardData.rewards_savings) {
          if (data.data.rewards_savings.today.total_reward_saving !== this.dashboardData.rewards_savings.today.total_reward_saving) {
            // this.coinCollect = true;
            // setTimeout(() => {
            //   this.coinCollect = false;
            // }, 3000);
            this.coin.nativeElement.play();
          }
        }
        this.dashboardData = data.data;
      },
      error: (error: any) => {
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }


  getTableData(firstTime:any) {
    this.loading = true;
    this.dashboardService.getTableData().subscribe({
      next: (data: any) => {
        this.loading = false;
        if(firstTime){
          this.firework.nativeElement.play();
        }
        if (this.tableData && this.tableData.length > 0) {
          const uniqueData = data.data.filter((obj2: any) => !this.tableData.find((obj1: any) => obj1.rewards_transactions_id === obj2.rewards_transactions_id));
          // if (this.tableData != null && this.tableData.length > 0) {
          // let transactionId = this.tableData[0].rewards_transactions_id;
          // let transactionId2 = localStorage.getItem("transactionId");
          // if (transactionId != transactionId2) {
          if (uniqueData && uniqueData.length > 0) {
            let j=uniqueData.length;
            let i=0;

            this.tableRowUpdateInterval = setInterval(() => {
              this.simulateDataUpdate(uniqueData[i]);
              i++;
              if(i==j){
                clearInterval(this.tableRowUpdateInterval);
              }
            }, 2000);
            this.popup();
            // this.winnerAvailable = true;
            this.firework.nativeElement.play();
            setTimeout(() => {
              // this.winnerAvailable = false;
              this.closeWinnerPopup();
            }, 8000);
          }
          // }
          // localStorage.setItem("transactionId", this.tableData[0].rewards_transactions_id);
          // }
        } else {
          this.tableData = data.data;
        }
        // this.tableData = [];

        setTimeout(() => {
          this.getHeight();
        }, 1000);

      },
      error: (error: any) => {
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }

  getReverdTransactionList(data: any) {
    this.loading = true;
    this.dashboardService.getReverdTransactionList().subscribe({
      next: (data: any) => {
        this.reverdTransactionList = data.data;
        if (this.reverdTransactionList != null && this.reverdTransactionList.rewards_data && this.reverdTransactionList.rewards_data.length > 0) {
          this.rewardsTransactionPopupDetails = this.reverdTransactionList.rewards_data[this.popUpPage];
          let obj2 = {
            user_id: this.rewardsTransactionPopupDetails.user_id
          }
          this.getSpinGaneRewardsList(obj2);
          let obj3 = {
            user_id: this.rewardsTransactionPopupDetails.user_id,
            reward_id: this.rewardsTransactionPopupDetails.rewards_id
          }
          this.getRewardsPopupDetails(obj3);
          this.loading = false;
        }
      },
      error: (error: any) => {
        this.loading = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        // Optional: Logic to handle completion
      }
    });
  }


  getSpinGaneRewardsList(dataObj: any) {
    this.loading = true;
    this.loading2 = true;

    this.dashboardService.getSpinGaneRewardsList(dataObj).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.loading2 = false;
        // this.spinGaneRewardsList = [];

        this.popupindex = data.data.spin_rewards_list.findIndex((obj: any) => obj.rewards_id == this.rewardsTransactionPopupDetails.rewards_id);
        if (this.popupindex == -1) {
          let obj = {
            page_no: this.pageSize
          }
          this.getReverdTransactionList(obj);
          return;
        }
        this.spinGaneRewardsList = data.data;
        setTimeout(() => {
          this.initilizaSwiper();
        }, 50);

      },
      error: (error: any) => {
        this.loading = false;
        this.loading2 = false;
        console.log(error);
      },
      complete: () => {
        this.loading = false;
        this.loading2 = false;
        // Optional: Logic to handle completion
      }
    });
  }

  getRewardsPopupDetails(data: any) {
    this.dashboardService.getRewardsPopupDetails(data).subscribe({
      next: (data: any) => {
        this.rewardsPopupDetails = data;
        console.log(data);
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        console.log("optional");
        // Optional: Logic to handle completion
      }
    });
  }



  ngAfterViewInit() {
    // const Swiper = require('swiper');
    // this.initilizaSwiper();
  }
  videoSource: string = 'assets/video/BG_30.mp4';

  initilizaSwiper() {
    this.mySwiper = new Swiper(this.el.nativeElement.querySelector('.swiper'), {
      slidesPerView: 4,
      direction: 'vertical',
      rewind: true,
      speed: 300,
      centeredSlides: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    setTimeout(() => {
      this.change();
    }, 200);
  }
  spinnerSpeed = 150;
  popupindex = 0;
  change() {
    this.spinnerSpeed = 150;
    this.i = 3;
    this.startIntervel();
    // for (let l = 0; l < 60; l++) {
    //   setTimeout(() => {
    //     this.mySwiper.slideTo(this.i++);
    //     if (this.i == this.spinGaneRewardsList.spin_rewards_list.length) {
    //       this.i = 0;
    //     }
    //   }, 500);
    // }
    // setTimeout(() => {
    //   this.nagativePopup = true;
    // }, 12000);
    // setTimeout(() => {
    //   this.nagativePopup = false;
    // }, 18000);
  }



  startIntervel() {
    this.myInterval = setInterval(() => {
      this.mySwiper.slideTo(this.i++);
      if (this.i == this.spinGaneRewardsList.spin_rewards_list.length) {
        this.i = 0;
      }
      if (this.spinGaneRewardsList.spin_rewards_list[this.i].rewards_id == this.rewardsTransactionPopupDetails.rewards_id) {
        clearInterval(this.myInterval);
        setTimeout(() => {
          if (this.rewardsPopupDetails != null) {
            console.log("show popup")
            this.showPopup(this.rewardsPopupDetails.data, true);
          }
          // this.nagativePopup = true;
        }, 400);
        setTimeout(() => {
          // this.nagativePopup = false;
          clearInterval(this.myInterval);
          this.showPopup(this.rewardsPopupDetails.data, false);
          if (this.popUpPage == 9) {
            if (this.reverdTransactionList.flag) {
              this.pageSize++;
              this.updatePageSize(this.pageSize);
            }
            this.popUpPage = 0;
            this.updatePopupPage(this.popUpPage);
            clearInterval(this.myInterval);
            let obj = {
              page_no: this.pageSize
            }
            console.log("size exist " + this.pageSize);
            this.getReverdTransactionList(obj);
            return;
          }
          this.popUpPage++;
          this.updatePopupPage(this.popUpPage);
          if (this.reverdTransactionList.rewards_data.length > this.popUpPage) {
            this.rewardsTransactionPopupDetails = this.reverdTransactionList.rewards_data[this.popUpPage];
            let obj2 = {
              user_id: this.rewardsTransactionPopupDetails.user_id
            }
            clearInterval(this.myInterval);
            this.getSpinGaneRewardsList(obj2);
            let obj3 = {
              user_id: this.rewardsTransactionPopupDetails.user_id,
              reward_id: this.rewardsTransactionPopupDetails.rewards_id
            }
            this.getRewardsPopupDetails(obj3)
          }
        }, 1800);
        return;
      }
      const speedDeffrance = this.popupindex - this.i;
      if (speedDeffrance > 60) {
        if (this.spinnerSpeed != 100) {
          this.spinnerSpeed = 100;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 15) {
        if (this.spinnerSpeed != 150) {
          this.spinnerSpeed = 150;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 10) {
        if (this.spinnerSpeed != 200) {
          this.spinnerSpeed = 200;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 6) {
        if (this.spinnerSpeed != 250) {
          this.spinnerSpeed = 250;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      } else if (speedDeffrance > 3) {
        if (this.spinnerSpeed != 300) {
          this.spinnerSpeed = 300;
          clearInterval(this.myInterval);
          this.startIntervel();
        }
      }


    }, this.spinnerSpeed);
  }
  changeSwiper() {
    this.mySwiper.slideTo(this.i++);
    if (this.i == this.spinGaneRewardsList.spin_rewards_list.length) {
      this.i = 0;
    }
  }

  showPopup(data: any, value: boolean) {
    switch (data.is_check_positive_negative_point) {
      case "0":
        switch (data.rewards_type_id) {
          case "1":
            this.raffleReward = value;
            break;
          case "2":
            this.positivePopUp = value;
            break;
          case "3":
            this.positivePopUp = value;
            break;
          case "4":
            this.positivePopUp = value;
            break;
          case "7":
            this.raffleReward = value;
            break;
          default:
            break;
        }
        break;
      case "1":
        this.positivePopUp = value;
        break;
      case "2":
        this.nagativePopup = value;
        break;
      default:
        break;
    }
  }

  playWinnerSound(){
    this.firework.nativeElement.play();
   
  }

  playCoinSound(){
    this.coin.nativeElement.play();
  }





  closeWinnerPopup() {
    $('#demoModal').modal('hide');
    const winnerElement: HTMLElement = document.getElementById("winner-overlay")!;
    if (winnerElement) {
      winnerElement.className = "";
    }
  }

  popup() {
    $('#demoModal').modal('show');
    const winnerElement: HTMLElement = document.getElementById("winner-overlay")!;
    if (winnerElement) {
      winnerElement.className += " display";
    }
  }

}