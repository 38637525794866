import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }


  getHttpParamsOptions(parameter: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), withCredentials: true,
      params: parameter
    };
    return httpOptions;
  }

  getOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getDashboardData() {
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/dashboard.json",this.getOptions());
    // }else{
      return this.http.get<any>(this.baseUrl + "dashboard",this.getOptions());
    // }
    
  }

  getTableData() {
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/table.json",this.getOptions());
    // }else{
      return this.http.get<any>(this.baseUrl + "all_rewards_transaction_history",this.getOptions());
    // }
  }

  getTableData2(data: any) {
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/table.json",this.getOptions());
    // }else{
      return this.http.post<any>(this.baseUrl + "all_rewards_transaction_history",data,this.getOptions());
    // }
  }

  getReverdTransactionList() {
    // return this.http.get<any>("assets/json/rewardTransactionList0.json",this.getOptions());
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/rewardTransactionList0.json",this.getOptions());
    // }else{
    return this.http.get<any>(this.baseUrl + "rewards_transaction_list",this.getOptions());
    // }
  }

  getSpinGaneRewardsList(data: any) {
    // return this.http.get<any>("assets/json/spinRewordList.json",this.getOptions());
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/spinn.json",this.getOptions());
    // }else{
    return this.http.post<any>(this.baseUrl + "spin_game_rewards_list", data,this.getOptions());
    // }
  }

  getRewardsPopupDetails(data: any) {
    // return this.http.post<any>(this.baseUrl + "rewards_popup_details", data,this.getOptions());
    return this.http.post<any>(this.baseUrl + "rewards_popup_details", data,this.getOptions());
  }

  getFutureSavingEarn() {
    // return this.http.get<any>("assets/json/spinRewordList.json",this.getOptions());
    // if(isLocal=='true'){
    //   return this.http.get<any>("assets/json/future.json",this.getOptions());
    // }else{
    return this.http.get<any>(this.baseUrl + "future_saving_earn_history",this.getOptions());
    // }
  }


}
