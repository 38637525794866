<!-- <div id="loading"></div> -->
<!-- <div class="loading" *ngIf="loading"><img src="../../../assets/images/loader.gif"></div> -->
<!-- <video autoplay muted loop id="myVideo">
  <source src="assets/video/BG_30.mp4" type="video/mp4">
</video> -->
<!-- app.component.html -->
<video loop muted autoplay  #video   id="myVideo" (canplay)="video.play()"
(loadedmetadata)="video.muted = true">
  <source [src]="videoSource" type="video/mp4">
</video>

<audio #firework controls style="visibility: hidden; position: absolute;">
  <source src="assets/audio/Firework.mp3" type="audio/mp3">
</audio>

<audio #coin controls style="visibility: hidden; position: absolute;">
  <source src="assets/audio/GameCoinsCollected.mp3" type="audio/mp3">
</audio>


<main class="background_image" style="font-family: 'Myriad Pro'">
  <section class="py-60 MainBG">
    <div class="row m_0">
      <div class="col-8 p_l_0 p_r_0">
        <div class="row">
          <div class="col-lg-4 p_r" >
            <!-- <img src="assets/images/Side_Number_Panels_STATIC.gif" alt="" class="m-auto wd_100"> -->
           <div class="row" style="background-image: url('../../../assets/images/Side_Number_Panels_STATIC.gif');    background-size: contain;
           background-repeat: no-repeat;">
            <div class="col-12 year_reward_saving_txt_1">{{dashboardData?.rewards_savings?.yearly?.title}}</div>
            <div class="col-12 year_reward_saving_no_1">{{dashboardData?.rewards_savings?.yearly?.total_reward_saving}}</div>
            <div class="col-12 todays_reward_saving_txt_1">{{dashboardData?.rewards_savings?.today?.title}}</div>
            <div class="col-12 todays_reward_saving_no_1">{{dashboardData?.rewards_savings?.today?.total_reward_saving}}</div>
           </div>
           <!-- <div class="row">
            <div class="col-12">{{dashboardData?.rewards_savings?.yearly?.total_reward_saving}}</div>
           </div>
           <div class="row">
            <div class="col-12">{{dashboardData?.rewards_savings?.today?.title}}</div>
           </div>
           <div class="row">
            <div class="col-12">{{dashboardData?.rewards_savings?.today?.total_reward_saving}}</div>
           </div> -->
            <!-- <p class="year_reward_saving_txt">{{dashboardData?.rewards_savings?.yearly?.title}}</p>
            <p class="year_reward_saving_no">{{dashboardData?.rewards_savings?.yearly?.total_reward_saving}}</p>
            <p class="todays_reward_saving_txt">{{dashboardData?.rewards_savings?.today?.title}}</p>
            <p class="todays_reward_saving_no">{{dashboardData?.rewards_savings?.today?.total_reward_saving}}</p> -->

          </div>
          <div class="col-lg-8">
            <!-- <div class="row">
              <div class="col-12 txt_a_r">
                <h1 class="top_heading" style="line-height: 1em;">LIVE LOOK IN</h1>
                <h3 style="color: #fff;line-height: 1em;">{{spinGaneRewardsList?.user_full_name}}</h3>
              </div>
            </div> -->
            <div class="row" style="margin-top: 5px;background: #80808029;" #yourDiv>
              <div class="txt_a_c">
                <span class="table_heading_1">TODAY'S REWARDS AT</span>
                <span class="table_heading_2"> Matthews Auto</span>
              </div>
              <table class="table align-middle text-white mb-0 table_font_size" style="margin-left: 15px;">
              <tr *ngFor="let item of tableData; let i = index" [@rowAnimation]="i">
                  <td style="padding-top: 2%;" >{{item?.added_time}}</td>
                  <td style="padding-top: 2%;" class="font_w_800">{{item?.user_full_name}}</td>
                  <td style="padding-top: 2%;">{{item?.rewards_offer_title}}</td>
                  <td style="padding-top: 2%;">{{item?.business_name}}</td>
                </tr> 
               
              </table>
            </div>
          </div>
        </div>
        <div class="row MainDesktop" >
          <div class="col-5 banner_col" style="margin-top: 1%;">
            <div class="Banner banner_height_100">
              <div id="carouselExampleIndicators" class="carousel slide banner_height_100" data-bs-ride="carousel">
                <div class="carousel-indicators bottom_bar">
                  <button class="roundshap" *ngFor="let item of dashboardData?.slider_images; let i = index"
                    type="button" [attr.data-bs-target]="'#carouselExampleIndicators'" [attr.data-bs-slide-to]="i"
                    [class.active]="i === 0" [attr.aria-label]="'Slide ' + (i + 1)"></button>
                </div>
                <div class="carousel-inner banner_height_100">
                  <div *ngFor="let item of dashboardData?.slider_images; let i = index"
                    class="carousel-item banner_height_100" [class.active]="i === 0">
                    <img [src]="item?.slider_image" class="d-block banner_height_widht_100" [alt]="'Slide ' + (i + 1)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <!-- <div class="text-center m-auto"> -->
              <!-- <img src="assets/images/MattyConfetti_10sec_scaled.gif" alt="" class="m-auto img_width"> -->
              <img src="assets/images/1_gif.gif" alt="" class="m-auto img_width" class="matty_animation" *ngIf="image1">
              <img src="assets/images/2_gif.gif" alt="" class="m-auto img_width" class="matty_animation" *ngIf="image2">
              <img src="assets/images/3_gif.gif" alt="" class="m-auto img_width" class="matty_animation" *ngIf="image3">
              <img src="assets/images/4_gif.gif" alt="" class="m-auto img_width" class="matty_animation" *ngIf="image4">
              <img src="assets/images/5_gif.gif" alt="" class="m-auto img_width" class="matty_animation" *ngIf="image5">
            <!-- </div> -->
          </div>

          <div class="col-2 " style="padding-left: 0px; text-align: right;">
            <span class="play_now_text_1">PLAY</span><br>
            <span class="play_now_text_1">NOW!</span>
            <img [src]="dashboardData?.web_qr_code_image" alt="" class="m-auto" style="width: 77%;
              height: 35%;
              position: relative;">
          </div>
          <!-- <div class="col-2 play_now_text" style="padding-left: 0px;">
            <span class="play_now_text_1">PLAY</span><br>
            <span class="play_now_text_1">NOW!</span>
          </div>
          <div class="col-1" style="    padding-left: 0px;
    padding-right: 0px;">
              <img [src]="dashboardData?.web_qr_code_image" alt="" class="m-auto" style="width: 114%;
              height: 40%;
              position: relative;
              top: 37%;">
          </div> -->
        </div>
      </div>
      <div class="col-4 p_l_0">
        <div class="row">
          <div class="col-12 txt_a_c" style="padding-bottom: 10px;">
            <h3 class="top_heading" style="line-height: 1em;">LIVE LOOK IN</h3>
            <h5 style="color: #fff;line-height: 1em;min-height: 20px;">{{spinGaneRewardsList?.user_full_name}}</h5>
          </div>
        </div>
        <div class="row">
        <div class="MobileScreen">
          <!-- <div class="loading2" *ngIf="loading2"><img src="../../../assets/images/loading_mobile.gif"></div> -->
          <img src="../assets/images/spin_screen_bg@3x.png" alt="" class="w-100">

          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="TopBorder">
                <img src="../assets/images/top_Layer@2x.png" alt="">
              </div>
              <div class="swiper-slide" *ngFor="let item of spinGaneRewardsList?.spin_rewards_list">
                <div class="InnerContanet">
                  <h4 class="wrap_text swiper_spinner_heading">{{item?.business_name}}</h4>
                  <h4 class="wrap_text">{{item?.rewards_title}}</h4>
                  <h6 class="wrap_text">{{item?.rewards_offer_title}}</h6>
                </div>
              </div>
              <div class="BottomBorder">
                <img src="../assets/images/bottom_layer@2x.png" alt="">
              </div>

            </div>
          </div>
          <div class="swiper-button-next">
            <img src="../assets/images/spin_button@2x.png" alt="">
          </div>

          <div class="LogoTop">
            <img [src]="spinGaneRewardsList?.spin_the_wheel_header" alt="">
          </div>
          <div class="leaders" (click)="simulateData()">
            <p class="m-0">LEADERS</p>
          </div>
          <div class="rewards" (click)="playWinnerSound()">
            <p class="m-0">REWARDS</p>
          </div>
          <div class="PointBlance">
            <p class="m-0">Point Balance <span style="font-family: 'Myriad Pro';
              color: #002D62;
              font-size: 20px;
              font-weight: 9">{{spinGaneRewardsList?.point_balance}}</span></p>
          </div>
          <div class="PointBlance2">
            <img src="../assets/images/outer_inner_blue2x.png" alt="" style="    width: 273px;
            height: 455px;">
          </div>
          <div class="BottomBar">
            <div class="BottomIcons">
              <img src="../assets/images/message@2x.png" alt="">
            </div>
            <div class="CenterIcon">
              <img src="../assets/images/tab_logo@2x.png" alt="">
            </div>
            <div class="BottomIcons">
              <img src="../assets/images/menu@2x.png" alt="">
            </div>
          </div>
          <div class="Popup01" [ngClass]="[ nagativePopup ? 'pupup1' : 'pupup1None']">
            <div class="bussinessinfo">
              <div class="InnerInfo">
                <div class="TopItitle">
                  <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                </div>
                <div class="RewD">
                  <h3>You Lose</h3>
                </div>
                <div class="Claim">
                  <p>-{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points, Wll ok then</p>
                  <div class="GroupBtn">
                    <button>Keep spinning</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Popup02" [ngClass]="[ positivePopUp ? 'pupup1' : 'pupup1None']">
            <div class="bussinessinfo">
              <div class="InnerInfo">
                <div class="TopItitle">
                  <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                </div>
                <div class="RewD">
                  <h3>+{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points! Nice!</h3>
                </div>
                <div class="Claim">
                  <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                  <div class="GroupBtn">
                    <button>Let it Ride</button>&nbsp;&nbsp;
                    <button>Claim</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Popup02" [ngClass]="[ everyDayReward ? 'pupup1' : 'pupup1None']">
            <div class="bussinessinfo">
              <div class="InnerInfo">
                <div class="TopItitle">
                  <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                </div>
                <div class="RewD">
                  <h3>+{{rewardsPopupDetails?.data?.deduct_rewards_point}} Points! NIce!</h3>
                </div>
                <div class="Claim">
                  <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                  <div class="GroupBtn">
                    <button>Claim</button>&nbsp;&nbsp;
                    <button>Decline</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Popup02" [ngClass]="[ raffleReward ? 'pupup1' : 'pupup1None']">
            <div class="bussinessinfo">
              <div class="InnerInfo">
                <div class="TopItitle">
                  <h3>{{rewardsPopupDetails?.data?.rewards_type_name}}</h3>
                  <p>{{rewardsTransactionPopupDetails?.rewards_offer_title}}</p>
                </div>
                <div class="RewD">
                  <img [src]="rewardsPopupDetails?.data?.rewards_image_url" alt="" class="m-auto img_width_30">
                </div>
                <div class="Claim">
                  <h3>{{rewardsTransactionPopupDetails?.reward_description}}</h3>
                  <p>{{rewardsPopupDetails?.data?.deduct_rewards_point}} Point to claim</p>
                  <div class="GroupBtn">
                    <button>Claim</button>&nbsp;&nbsp;
                    <button>Decline</button>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
        <!-- <div class="row">
          <div class="col-2"></div>
          <div class="col-4 play_now_text" >
            <span class="play_now_text_1">PLAY</span><br>
            <span class="play_now_text_1">NOW!</span>
          </div>
          <div class="col-4">
              <img [src]="dashboardData?.web_qr_code_image" alt="" class="m-auto" style="width: 72%;
              position: relative;
              top: 29%;">
          </div>
          <div class="col-2"></div>
        </div> -->
      </div>
    </div>
    <!-- </div> -->
  </section>
</main>


<div id="winner-overlay">
  <!-- <button type="button" (click)="closeWinnerPopup()" style="margin-right: 20px;
   margin-top: 15px; color: #fff;" class="close">Close
  </button> -->
  <div class="pyro">

    <div class="before"></div>
    <div class="after"></div>
  </div>
</div>